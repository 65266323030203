import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";
import { Floor } from "./useVehicle";

type Category = "tram" | "bus" | "mobilis";

export interface Vehicle {
  category: Category;
  kmk_id: string;
  full_kmk_id: string | null;
  full_model_name: string | null;
  short_model_name: string | null;
  floor: Floor | null;
}

function useFetchVehicles() {
  const { data, error } = useFetch<{ vehicles: Vehicle[] }>(
    `${BACKEND_BASE_URL}/api/vehicles`
  );

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setVehicles(data.vehicles);
      setLoading(false);
    }
  }, [data]);

  return { vehicles, loading, error };
}

export function useVehicles() {
  const { vehicles, loading, error } = useFetchVehicles();

  window.localStorage.removeItem("vehicles");

  const [localStorage, setLocalStorage] = useLocalStorage<Vehicle[] | null>(
    "vehicles_v2",
    null
  );

  // update local storage after fetch
  useEffect(() => {
    if (!loading) {
      setLocalStorage(vehicles);
    }
  }, [loading, setLocalStorage, vehicles]);

  if (loading && localStorage !== null) {
    return { vehicles: localStorage, loading: false, error: undefined };
  }

  return { vehicles, loading, error };
}
