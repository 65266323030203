import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import PageTitle from "../components/PageTitle";
import { getUrlForPrivacyPolicy } from "../urls";
import { Link } from "react-router-dom";

const AUTHOR_EMAIL =
  process.env.PUBLIC_URL === "https://tomekzaw.pl/ttss"
    ? "tomekzawadzki98+odjkrk@gmail.com"
    : "kontakt@odjazdowykrakow.pl";

const FACEBOOK_LINK = "https://www.facebook.com/odjazdowykrakow";

interface ExternalLinkProps {
  url: string;
}

function ExternalLink({ url }: ExternalLinkProps) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {url}
    </a>
  );
}

interface LinksProps {
  urls: string[];
}

function Links({ urls }: LinksProps) {
  return (
    <ul>
      {urls.map((url) => (
        <li key={url}>
          <ExternalLink url={url} />
        </li>
      ))}
    </ul>
  );
}

export function About() {
  return (
    <Container>
      <PageTitle title="Informacje" />
      <h1 className="my-4">Informacje</h1>
      <h4 className="mt-4 mb-3">Źródła danych</h4>
      <p>
        Niniejsza strona konsoliduje dane udostępniane przez Zarząd Transportu
        Publicznego w Krakowie oraz Miejskie Przedsiębiorstwo Komunikacyjne S.A.
        w Krakowie:
      </p>
      <Links
        urls={[
          "https://ztp.krakow.pl/dane-otwarte",
          "https://gtfs.ztp.krakow.pl/",
          "https://www.ttss.krakow.pl/internetservice/",
          "http://ttss.mpk.krakow.pl/internetservice/",
          "http://kokon.mpk.krakow.pl/",
        ]}
      />
      <p>
        Spis numerów taborowych pochodzi ze strony{" "}
        <ExternalLink url="https://psmkms.krakow.pl/ciekawostki/krakowski-tabor" />
        .
      </p>
      <h4 className="mt-4 mb-3">Zastrzeżenia</h4>
      <p>
        Prezentowane dane pochodzą z różnych źródeł i nie mamy wpływu na ich
        poprawność, aktualność i kompletność. Niektóre pojazdy nie są podłączone
        do systemu nadzoru ruchu. Mogą występować przejściowe problemy z
        dostępnością niektórych źródeł danych. Autor strony nie ponosi
        odpowiedzialności za ewentualne nieprawidłowości lub braki w
        prezentowanych danych pochodzących z zewnętrznych źródeł.
      </p>
      <h4 className="mt-4 mb-3">Polecane strony</h4>
      <Links
        urls={[
          "https://app.krakowpodreka.pl/",
          "https://mpk.jacekk.net/map.html",
          "https://beta.ttss.pl/map.html",
          "https://szymon.sanocki.info/mpk/",
          "https://czynaczas.pl/krakow",
          "https://pkk.info.pl/viewtopic.php?f=100&t=4882",
          "https://forum.pkk.net.pl/viewtopic.php?t=65",
          "https://krabok.wordpress.com/tag/ttss/",
        ]}
      />
      <h4 className="mt-4 mb-3">Kontakt</h4>
      <p className="mb-1">Autor: Tomasz Zawadzki</p>
      <p className="mb-1">
        E-mail:{" "}
        <a href={`mailto:${AUTHOR_EMAIL}`} target="_blank" rel="noreferrer">
          {AUTHOR_EMAIL}
        </a>
      </p>
      <p className="mb-1">
        Facebook:{" "}
        <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
          Odjazdowy Kraków
        </a>
      </p>
      <p className="mt-4 pb-5">
        <Link to={getUrlForPrivacyPolicy()}>
          <Button variant="outline-primary">Polityka prywatności</Button>
        </Link>
      </p>
    </Container>
  );
}
