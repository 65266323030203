import "./FavoriteStopToggle.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useCallback } from "react";
import { joinStopNameAndNumber } from "../utils";
import { useFavoriteStop } from "../hooks/useFavoriteStop";

const EMPTY_STAR = "☆";
const FULL_STAR = "★";

interface FavoriteStopToggleProps {
  stopName: string;
  stopNumber: string | null;
}

export function FavoriteStopToggle({
  stopName,
  stopNumber,
}: FavoriteStopToggleProps) {
  const { isFavorite, setFavorite } = useFavoriteStop(
    joinStopNameAndNumber(stopName, stopNumber)
  );

  const handleClick = useCallback(() => {
    setFavorite(!isFavorite);
  }, [setFavorite, isFavorite]);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {isFavorite ? "Usuń z ulubionych" : "Dodaj do ulubionych"}
        </Tooltip>
      }
    >
      <div
        className={`favorite-stop-toggle favorite-stop-toggle-${
          isFavorite ? "full" : "empty"
        }`}
        onClick={handleClick}
      >
        {isFavorite ? FULL_STAR : EMPTY_STAR}
      </div>
    </OverlayTrigger>
  );
}
