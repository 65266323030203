import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import { Fragment } from "react";
import PageTitle from "../components/PageTitle";
import { VehicleHistoryTable } from "../components/VehicleHistoryTable";
import { useParams } from "react-router-dom";
import { useVehicleHistory } from "../hooks/useVehicleHistory";

interface VehicleHistoryInnerProps {
  kmkId: string;
}

function VehicleHistoryInner({ kmkId }: VehicleHistoryInnerProps) {
  const { history, loading, error } = useVehicleHistory(kmkId);

  return (
    <Container>
      <PageTitle title="Historia pojazdu" />
      <h1 className="my-4">
        {kmkId.split("+").map((part, i) => (
          <Fragment key={i}>
            {i !== 0 && "+"}
            <small>{part.substring(0, 2)}</small>
            {part.substring(2, 5)}
          </Fragment>
        ))}
      </h1>
      <Alert variant="warning">
        Historia pojazdu nie jest dostępna z powodu awarii serwisu Kokon.
      </Alert>
      {error ? (
        <Error error={error} />
      ) : (
        <div className="col-lg-4">
          <VehicleHistoryTable history={history} loading={loading} />
        </div>
      )}
    </Container>
  );
}

export function VehicleHistory() {
  const { kmk_id } = useParams();

  if (kmk_id === undefined) {
    return null;
  }

  return <VehicleHistoryInner kmkId={kmk_id} />;
}
