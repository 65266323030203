import "./StatusIcon.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Counter } from "./Counter";
import { useSecondsFromTimestamp } from "../hooks/useSecondsFromTimestamp";

const liveThresholdInSeconds = 2 * 60;
const obsoleteThresholdInSeconds = 7 * 60;

export interface StatusIconProps extends React.HTMLAttributes<HTMLDivElement> {
  timestamp: number;
  tooltip?: boolean;
}

export function StatusIcon({
  timestamp,
  tooltip = false,
  ...props
}: StatusIconProps) {
  const seconds = useSecondsFromTimestamp(timestamp);

  const className = `status-icon status-icon-${
    seconds < liveThresholdInSeconds
      ? "live"
      : seconds <= obsoleteThresholdInSeconds
      ? "obsolete"
      : "outdated"
  }`;

  if (tooltip) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <Counter timestamp={timestamp} />
          </Tooltip>
        }
      >
        <div className={className} {...props} />
      </OverlayTrigger>
    );
  }

  return <div className={className} {...props} />;
}
