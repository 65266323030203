import { useEffect, useState } from "react";

const interval = 1000;

export function useSecondsFromTimestamp(timestamp: number) {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const id = setInterval(() => setNow(Date.now()), interval);
    return () => clearInterval(id);
  }, []);

  const seconds = Math.round(now / 1000 - timestamp);

  return seconds;
}
