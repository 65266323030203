import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { RouteGroups } from "../components/RouteGroups";
import Spinner from "react-bootstrap/Spinner";
import { useRoutes } from "../hooks/useRoutes";

export function Routes() {
  const { groups, loading, error } = useRoutes();

  return (
    <Container className="pb-5">
      <PageTitle title="Linie" />
      <h1 className="my-4">Linie</h1>
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <RouteGroups groups={groups} />
      )}
    </Container>
  );
}
