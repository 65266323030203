import { HiddenTrip, useHiddenTrips } from "../hooks/useHiddenTrips";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import { HiddenTripsList } from "../components/HiddenTripsList";
import PageTitle from "../components/PageTitle";
import { Service } from "../hooks/useBlocks";
import { ServiceSelect } from "../components/ServiceSelect";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";

interface InnerProps {
  hiddenTrips: HiddenTrip[];
  services: Service[];
}

function Inner({ hiddenTrips, services }: InnerProps) {
  const [serviceId, setServiceId] = useState(services[0]?.service_id);

  return (
    <>
      <div className="mb-3">
        <ServiceSelect
          services={services}
          serviceId={serviceId}
          setServiceId={setServiceId}
        />
      </div>
      {serviceId !== undefined ? (
        <HiddenTripsList
          hiddenTrips={hiddenTrips.filter(
            (hiddenTrip) => hiddenTrip.service_id === serviceId
          )}
        />
      ) : hiddenTrips.length > 0 ? (
        <Alert variant="secondary">Wybierz dzień tygodnia.</Alert>
      ) : (
        <Alert variant="warning">Brak brygad.</Alert>
      )}
    </>
  );
}

export function HiddenTrips() {
  const { hiddenTrips, services, loading, error } = useHiddenTrips();

  return (
    <Container className="pb-5">
      <PageTitle title="Kursy zjazdowe i wyjazdowe" />
      <h1 className="my-4">Kursy zjazdowe i wyjazdowe</h1>
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Inner hiddenTrips={hiddenTrips} services={services} />
      )}
    </Container>
  );
}
