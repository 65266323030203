import { Stop } from "../hooks/useRoute";
import { StopLink } from "./StopLink";

interface StopsListProps {
  stops: Stop[];
  directions: string[];
}

export function StopsList({ stops, directions }: StopsListProps) {
  const stopNames = stops.map((stop) => stop.stop_name);

  const uniqueStopNames = stopNames.filter(
    (item, pos) => stopNames.indexOf(item) === pos
  );

  return (
    <ul>
      {uniqueStopNames.map((stopName) => (
        <li key={stopName}>
          <StopLink
            stopName={stopName}
            bold={directions.includes(stopName)}
            link
          />
        </li>
      ))}
    </ul>
  );
}
