import Container from "react-bootstrap/Container";
import PageTitle from "../components/PageTitle";

export function PrivacyPolicy() {
  return (
    <Container>
      <div className="col-lg-8">
        <PageTitle title="Polityka prywatności" />
        <h1 className="my-4">Polityka prywatności</h1>
        <ol>
          <li className="mb-2">
            Aplikacja nie gromadzi ani nie udostępnia jakimkolwiek podmiotom
            trzecim żadnych danych osobowych ani takich, które mogłyby w
            jakikolwiek sposób pozwolić na identyfikację lub ustalenie
            tożsamości użytkownika.
          </li>
          <li className="mb-2">
            Aplikacja anonimowo zbiera dane statystyczne dotyczące sposobu
            użytkowania aplikacji, m.in. lokalizacji użytkownika, za pomocą
            następujących usług:
            <ol type="a">
              <li>Google Analytics, której właścicielem jest Google LLC,</li>
              <li>
                Sentry, której właścicielem jest Functional Software, Inc.
              </li>
            </ol>
          </li>
          <li className="mb-2">
            Mapa korzysta z zewnętrznej usługi CartoCDN Basemaps.
          </li>
          <li className="mb-2">
            Aplikacja korzysta z następujących uprawnień:
            <ol type="a">
              <li>
                Lokalizacja &ndash; w celu wyświetlenia pozycji oraz orientacji
                użytkownika na mapie.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </Container>
  );
}
