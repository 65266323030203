import { useEffect } from "react";
import { APP_NAME } from "../config";

function setPageTitle(title: string) {
  document.title = title;
}

interface PageTitleProps {
  title: string;
}

export default function PageTitle({ title }: PageTitleProps) {
  useEffect(() => {
    setPageTitle(`${title} – ${APP_NAME}`);
  }, [title]);

  useEffect(() => {
    return () => {
      setPageTitle(APP_NAME);
    };
  }, []);

  return null;
}
