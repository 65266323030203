import { useEffect, useMemo, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { encodeStopName, joinStopNameAndNumber } from "../utils";
import { useFetch } from "usehooks-ts";

export type Category = "tram" | "bus" | "mobilis";

export interface Departure {
  category: Category;
  block_id: string;
  trip_id: string;
  service_id: string;
  departure_time: string;
  time: number | null;
  arrival: boolean;
  hidden: boolean;
}

export function useDepartures(
  routeShortName: string,
  directionId: number,
  stopName: string,
  stopNumber: string | null
) {
  const encodedStopNameWithNumber = useMemo(
    () => encodeStopName(joinStopNameAndNumber(stopName, stopNumber)),
    [stopName, stopNumber]
  );

  const { data, error } = useFetch<{ departures: Departure[] }>(
    `${BACKEND_BASE_URL}/api/routes/${routeShortName}/directions/${directionId}/stops/${encodedStopNameWithNumber}/departures`
  );

  const [departures, setDepartures] = useState<Departure[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setDepartures(data.departures);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when route changes
    setDepartures([]);
    setLoading(true);
  }, [routeShortName, directionId, stopName]);

  return { departures, loading, error };
}
