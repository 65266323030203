import Map, {
  AttributionControl,
  GeolocateControl,
  MapLayerMouseEvent,
  Marker,
} from "react-map-gl/maplibre";
import busIcon from "../assets/bus.svg";
import tramIcon from "../assets/tram.svg";
import { useStopsByName } from "../hooks/useStopsByName";
import { Stop } from "../hooks/useStops";
import { useCallback, useEffect, useMemo, useRef } from "react";
import bbox from "@turf/bbox";
import { featureCollection, point } from "@turf/helpers";
import { useNavigate } from "react-router-dom";
import { getUrlForStop, getUrlForStopWithNumber } from "../urls";

interface StopMarkerProps {
  name: string;
  number: string | null;
  tram: boolean;
  latitude: number;
  longitude: number;
  selected: boolean;
}

function StopMarker({
  name,
  number,
  tram,
  latitude,
  longitude,
  selected,
}: StopMarkerProps) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (selected) {
      navigate(getUrlForStop(name), { replace: true });
    } else if (number !== null) {
      navigate(getUrlForStopWithNumber(name, number), { replace: true });
    }
  }, [navigate, selected, name, number]);

  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      style={{ zIndex: selected ? 4 : number !== null ? 3 : 2 }}
      onClick={handleClick}
    >
      <div
        style={{
          position: "relative",
          border: selected ? "3px solid lime" : undefined,
        }}
      >
        <img src={tram ? tramIcon : busIcon} alt="" style={{ width: 26 }} />
        {number && (
          <span
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: selected ? "lime" : "yellow",
              position: "absolute",
              bottom: 1.5,
              right: 2,
              lineHeight: 0.75,
            }}
          >
            {number}
          </span>
        )}
      </div>
    </Marker>
  );
}

function geojsonFromStops(stops: Stop[]) {
  return featureCollection(
    stops
      .filter((stop) => stop.stop_lat !== null && stop.stop_lon !== null)
      .map((stop) => point([stop.stop_lon!, stop.stop_lat!], stop))
  );
}

interface StopsMapProps {
  stopName: string;
  stopNumber: string | null;
}

export function StopsMap({ stopName, stopNumber }: StopsMapProps) {
  const { stops, error } = useStopsByName(stopName);

  const bounds = useMemo(() => {
    return bbox(geojsonFromStops(stops)) as [number, number, number, number];
  }, [stops]);

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      // @ts-expect-error
      mapRef.current?.fitBounds(bounds, {
        padding: 100,
        maxZoom: 17,
        duration: 0,
      });
    }
  }, [bounds]);

  const navigate = useNavigate();

  const handleClick = useCallback(
    (event: MapLayerMouseEvent) => {
      if ((event.originalEvent.target as Node).nodeName === "CANVAS") {
        navigate(getUrlForStop(stopName), { replace: true });
      }
    },
    [navigate, stopName]
  );

  if (!stops.length || error) {
    return null;
  }

  return (
    <div style={{ aspectRatio: 1 }}>
      <Map
        ref={mapRef}
        initialViewState={{ bounds, fitBoundsOptions: { padding: 100 } }}
        minZoom={9}
        bearing={0}
        pitchWithRotate={false}
        attributionControl={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        onClick={handleClick}
      >
        {stops
          .filter((stop) => stop.stop_lat !== null && stop.stop_lon !== null)
          .map((stop) => (
            <StopMarker
              name={stopName}
              number={stop.stop_num}
              tram={stop.tram}
              latitude={stop.stop_lat!}
              longitude={stop.stop_lon!}
              selected={stopNumber === stop.stop_num}
              key={`${stop.stop_name}_${stop.stop_num}`}
            />
          ))}
        <GeolocateControl />
        <AttributionControl compact={false} />
      </Map>
    </div>
  );
}
