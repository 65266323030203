import { Arrival } from "./Arrival";
import { HiddenTrip } from "../hooks/useHiddenTrips";
import { Link } from "react-router-dom";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { StopLink } from "./StopLink";
import Table from "react-bootstrap/Table";
import { formatTime } from "../utils";
import { getUrlForTrip } from "../urls";

interface HiddenTripsListProps {
  hiddenTrips: HiddenTrip[];
}

export function HiddenTripsList({ hiddenTrips }: HiddenTripsListProps) {
  return (
    <Table
      size="sm"
      striped
      bordered
      hover
      className="table-td-align-middle table-layout-fixed"
    >
      <thead>
        <tr>
          <th style={{ width: "7%" }} className="text-center">
            Odjazd
          </th>
          <th style={{ width: "7%" }} className="text-center">
            Przyjazd
          </th>
          <th style={{ width: "6%" }} className="text-center">
            Linia
          </th>
          <th>Kierunek</th>
          <th style={{ width: "23%" }}>Przystanek początkowy</th>
          <th style={{ width: "23%" }}>Przystanek końcowy</th>
          <th style={{ width: "11%" }}></th>
        </tr>
      </thead>
      <tbody>
        {hiddenTrips.map((hiddenTrip) => (
          <tr
            key={`${hiddenTrip.block_id}_${hiddenTrip.trip_id}_${hiddenTrip.service_id}`}
          >
            <td className="text-center">
              {formatTime(hiddenTrip.departure_time)}
            </td>
            <td className="text-center">
              {hiddenTrip.arrival_time && (
                <>
                  {formatTime(hiddenTrip.arrival_time)}
                  <Arrival />
                </>
              )}
            </td>
            <td className="text-center">
              <RouteShortNameBox routeShortName={hiddenTrip.route_short_name} />
            </td>
            <td className="text-truncate">
              <StopLink
                stopName={hiddenTrip.trip_headsign}
                expandDepotName
                removeNz
                bold
              />
            </td>
            <td className="text-truncate">
              {hiddenTrip.first_stop_name && (
                <StopLink stopName={hiddenTrip.first_stop_name} />
              )}
            </td>
            <td className="text-truncate">
              {hiddenTrip.last_stop_name && (
                <StopLink stopName={hiddenTrip.last_stop_name} removeNz />
              )}
            </td>
            <td className="text-center">
              <Link
                to={getUrlForTrip(
                  hiddenTrip.category,
                  hiddenTrip.service_id,
                  hiddenTrip.block_id,
                  hiddenTrip.trip_id
                )}
              >
                Rozkład kursu
              </Link>
            </td>
          </tr>
        ))}
        {hiddenTrips.length === 0 && (
          <tr>
            <td colSpan={7} className="py-3 text-center text-secondary">
              Brak kursów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
