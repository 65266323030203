import "./VehiclesByRouteTable.css";

import { compareKmkId, compareRouteShortName } from "../utils";

import { Counter } from "./Counter";
import { Floor } from "../hooks/useVehicle";
import { KmkId } from "./KmkId";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import { ScrollHorizontallyIfNeeded } from "./ScrollHorizontallyIfNeeded";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import { Vehicle } from "../hooks/useVehiclesByRoute";
import { useMemo } from "react";
import { StatusIcon } from "./StatusIcon";

const NO_ROUTE = "ZZZ"; // force to the end

function groupVehiclesByRoute<T extends Vehicle>(vehicles: T[]) {
  const map = new Map<string, T[]>();
  vehicles.forEach((vehicle) => {
    const route_short_name = vehicle.route_short_name ?? NO_ROUTE;
    if (map.has(route_short_name)) {
      map.get(route_short_name)!.push(vehicle);
    } else {
      map.set(route_short_name, [vehicle]);
    }
  });

  const keys = Array.from(map.keys());
  keys.sort(compareRouteShortName);

  const groups = keys.map((key) => ({
    route_short_name: key,
    vehicles: map.get(key)!,
  }));
  groups.forEach((group) =>
    group.vehicles.sort((a, b) => compareKmkId(a.kmk_id, b.kmk_id))
  );
  return groups;
}

function getBackgroundForFloor(floor: Floor | null) {
  switch (floor) {
    case "low_floor":
      return "low-floor";
    case "low_entry":
      return "low-entry";
    case "high_floor":
      return "high-floor";
    default:
      return "unknown-floor";
  }
}

function Legend() {
  return (
    <details className="mt-3">
      <summary className="bold">Legenda</summary>
      <ul className="list-unstyled">
        <li>
          <span className="low-floor">niskopodłogowy</span>
        </li>
        <li>
          <span className="low-entry">niskowejściowy</span>
        </li>
        <li>
          <span className="high-floor">wysokopodłogowy</span>
        </li>
        <li>
          <span className="unknown-floor">brak informacji</span>
        </li>
      </ul>
    </details>
  );
}

interface VehiclesByRouteTableProps {
  vehicles: (Vehicle & { is_live: boolean })[];
  showFloor: boolean;
}

export function VehiclesByRouteTable({
  vehicles,
  showFloor,
}: VehiclesByRouteTableProps) {
  const groups = useMemo(() => {
    return groupVehiclesByRoute(vehicles);
  }, [vehicles]);

  return (
    <>
      <ScrollHorizontallyIfNeeded>
        <Table bordered className="table-text-center w-auto mb-0">
          <thead>
            <tr>
              {groups.map((group) => (
                <th key={group.route_short_name}>
                  {group.route_short_name !== NO_ROUTE ? (
                    <RouteShortNameBox
                      routeShortName={group.route_short_name}
                    />
                  ) : (
                    <RouteShortNameBoxPlaceholder />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {groups.map((group) => (
                <td key={group.route_short_name}>
                  <div
                    style={{
                      columnCount: Math.ceil(group.vehicles.length / 20),
                      columnGap: 8,
                    }}
                  >
                    {group.vehicles.map((vehicle) => (
                      <OverlayTrigger
                        key={vehicle.kmk_id}
                        placement="right"
                        overlay={
                          <Tooltip>
                            <StatusIcon timestamp={vehicle.timestamp} />{" "}
                            <Counter timestamp={vehicle.timestamp} />
                          </Tooltip>
                        }
                      >
                        <div
                          className={
                            showFloor
                              ? getBackgroundForFloor(vehicle.floor)
                              : undefined
                          }
                        >
                          <div
                            className={
                              vehicle.is_live ? undefined : "opacity-25"
                            }
                          >
                            <KmkId kmk_id={vehicle.kmk_id} />
                          </div>
                        </div>
                      </OverlayTrigger>
                    ))}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </ScrollHorizontallyIfNeeded>
      {showFloor && <Legend />}
    </>
  );
}
