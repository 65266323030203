import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { getUrlForHome } from "../urls";

export function NotFound() {
  return (
    <Container>
      <h1 className="my-4">Nie znaleziono strony</h1>
      <Link to={getUrlForHome()}>
        <Button variant="outline-primary" className="mb-4">
          Strona główna
        </Button>
      </Link>
    </Container>
  );
}
