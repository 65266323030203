import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFilteredRouteShortNames } from "../hooks/useFilteredRouteShortNames";
import { useRoutes } from "../hooks/useRoutes";
import { compareRouteShortName } from "../utils";
import { RouteShortNameBox } from "./RouteShortNameBox";

interface RouteShortNameFilterModalProps {
  show: boolean;
  onClose: () => void;
}

export function RouteShortNameFilterModal({
  show,
  onClose,
}: RouteShortNameFilterModalProps) {
  const { groups } = useRoutes();

  const routeShortNames = useMemo(() => {
    const result = [];
    for (const group of groups) {
      result.push(...group.route_short_names);
    }
    result.sort(compareRouteShortName);
    return result;
  }, [groups]);

  const { hasFilter, shouldShowRoute, toggleFilter, clearFilter } =
    useFilteredRouteShortNames();

  return (
    <Modal size="lg" centered show={show} onHide={onClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Filtruj linie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ margin: -3 }}>
          {routeShortNames.map((routeShortName) => (
            <div
              key={routeShortName}
              style={{
                display: "inline-block",
                padding: 3,
                opacity: shouldShowRoute(routeShortName) ? 1 : 0.2,
                cursor: "pointer",
              }}
              onMouseDown={() => toggleFilter(routeShortName)}
            >
              <RouteShortNameBox
                routeShortName={routeShortName}
                large
                link={false}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {hasFilter && (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={clearFilter}
            disabled={!hasFilter}
          >
            Wyczyść filtr
          </Button>
        )}
        <Button size="sm" variant="outline-secondary" onClick={onClose}>
          Zamknij
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
