import {
  calculateInterval,
  formatTime,
  isTripHeadsignInternal,
} from "../utils";

import { Arrival } from "./Arrival";
import { KmkId } from "./KmkId";
import { Link } from "react-router-dom";
import { StatusIcon } from "./StatusIcon";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import Spinner from "react-bootstrap/Spinner";
import { StopLink } from "./StopLink";
import Table from "react-bootstrap/Table";
import { Trip } from "../hooks/useBlock";
import { ScrollHorizontallyIfNeeded } from "./ScrollHorizontallyIfNeeded";
import { getUrlForTrip } from "../urls";

interface BlockTripsProps {
  category: string;
  serviceId: string;
  blockId: string;
  trips: Trip[];
  loading: boolean;
}

export function BlockTrips({
  category,
  serviceId,
  blockId,
  trips,
  loading,
}: BlockTripsProps) {
  return (
    <ScrollHorizontallyIfNeeded>
      <Table
        size="sm"
        striped
        bordered
        hover
        className="table-td-align-middle"
        style={{ whiteSpace: "nowrap" }}
      >
        <thead>
          <tr>
            <th style={{ width: "4%" }} className="text-center px-2">
              Nr
            </th>
            <th style={{ width: "7%" }} className="text-center px-2">
              Odjazd
            </th>
            <th style={{ width: "7%" }} className="text-center px-2">
              Przyjazd
            </th>
            <th style={{ width: "6%" }} className="text-center px-2">
              Kurs
            </th>
            <th style={{ width: "6%" }} className="text-center px-2">
              Przerwa
            </th>
            <th style={{ width: "20%" }} className="text-end px-2">
              Przystanek początkowy
            </th>
            <th style={{ width: "5%" }} className="text-center px-2">
              Linia
            </th>
            <th style={{ width: "20%" }} className="px-2">
              Kierunek
            </th>
            <th style={{ width: "8%" }} className="text-center px-2">
              Pojazd
            </th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>
          {trips.map((trip, i) => (
            <tr
              key={trip.trip_num}
              className={trip.kmk_id ? "bg-warning bg-opacity-25" : undefined}
            >
              <td className="bold text-center px-2">{trip.trip_num}</td>
              <td className="text-center px-2">
                {trip.departure_time && formatTime(trip.departure_time)}
              </td>
              <td className="text-center px-2">
                {trip.arrival_time && (
                  <>
                    {formatTime(trip.arrival_time)}
                    <Arrival />
                  </>
                )}
              </td>
              <td className="text-center px-2">
                {calculateInterval(trip.departure_time, trip.arrival_time)}
              </td>
              <td className="text-center px-2">
                {i + 1 < trips.length &&
                  calculateInterval(
                    trip.arrival_time,
                    trips[i + 1].departure_time
                  )}
              </td>
              <td className="text-end px-2">
                {trip.stop_name && (
                  <StopLink
                    stopName={trip.stop_name}
                    expandDepotName
                    removeNz
                  />
                )}
              </td>
              <td className="text-center px-2">
                {!trip.hidden && trip.route_short_name ? (
                  <RouteShortNameBox routeShortName={trip.route_short_name} />
                ) : (
                  <RouteShortNameBoxPlaceholder />
                )}
              </td>
              <td className="px-2">
                <StopLink
                  stopName={trip.trip_headsign}
                  expandDepotName
                  removeNz
                  bold
                />
              </td>
              <td className="text-center px-2">
                {trip.kmk_id !== null && <KmkId kmk_id={trip.kmk_id} />}{" "}
                {trip.timestamp !== null && (
                  <StatusIcon timestamp={trip.timestamp} tooltip />
                )}
              </td>
              <td className="text-center px-2">
                {!isTripHeadsignInternal(trip.trip_headsign) && (
                  <Link
                    to={getUrlForTrip(
                      category,
                      serviceId,
                      blockId,
                      trip.trip_id
                    )}
                  >
                    Rozkład kursu
                  </Link>
                )}
              </td>
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan={10} className="py-3 text-center">
                <Spinner animation="border" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
          )}
          {!loading && trips.length === 0 && (
            <tr>
              <td colSpan={10} className="py-3 text-center text-secondary">
                Brak kursów.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollHorizontallyIfNeeded>
  );
}
