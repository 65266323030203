import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";

export interface StopGroup {
  stop_name: string;
  stop_lat: number | null;
  stop_lon: number | null;
}

function sortStopGroupsByName(original: StopGroup[]) {
  const copy = Array.from(original);
  copy.sort((a, b) => a.stop_name.localeCompare(b.stop_name));
  return copy;
}

function useFetchStopGroups() {
  const { data, error } = useFetch<{ stop_groups: StopGroup[] }>(
    `${BACKEND_BASE_URL}/api/stop_groups`
  );

  const [stopGroups, setStopGroups] = useState<StopGroup[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStopGroups(sortStopGroupsByName(data.stop_groups));
      setLoading(false);
    }
  }, [data]);

  return { stopGroups, loading, error };
}

export function useStopGroups() {
  const { stopGroups, loading, error } = useFetchStopGroups();

  window.localStorage.removeItem("stops");
  window.localStorage.removeItem("stops_v2");
  window.localStorage.removeItem("stops_v3");

  const [localStorage, setLocalStorage] = useLocalStorage<StopGroup[] | null>(
    "stop_groups",
    null
  );

  useEffect(() => {
    if (!loading) {
      setLocalStorage(stopGroups);
    }
  }, [loading, setLocalStorage, stopGroups]);

  if (loading && localStorage !== null) {
    return { stopGroups: localStorage, loading: false };
  }

  return { stopGroups, loading, error };
}
