import "./Delay.css";

interface DelayProps {
  seconds: number | null;
}

export function Delay({ seconds }: DelayProps) {
  if (seconds === null) {
    return null;
  }

  const minutes = Math.floor(seconds / 60);

  return (
    <div className="delay">
      {minutes > 0 ? (
        <sup className="bold text-danger">+{minutes}</sup>
      ) : minutes < 0 ? (
        <sup className="bold delay-minus">&ndash;{-minutes}</sup>
      ) : minutes === 0 ? (
        <sup className="bold text-primary">✓</sup>
      ) : null}
    </div>
  );
}
