import { Category } from "../../hooks/useActiveVehicles";

const color = "orange";
const opacity = 0.6;

interface SelectionIconProps {
  category: Category;
}

export function SelectionIcon({ category }: SelectionIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100}>
      {category === "tram" ? (
        <rect
          x={15}
          y={15}
          width={70}
          height={70}
          stroke={color}
          strokeWidth={6}
          strokeOpacity={opacity}
          fill="transparent"
        />
      ) : (
        <circle
          cx={50}
          cy={50}
          r={130 / 4 + 4}
          stroke={color}
          strokeWidth={8}
          strokeOpacity={opacity}
          fill="transparent"
        />
      )}
    </svg>
  );
}
