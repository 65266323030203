import { SelectionIcon } from "../components/map/SelectionIcon";
import { VehicleIcon } from "../components/map/VehicleIcon";
import { useMapImage } from "./useMapImage";

const icons = {
  tram: <VehicleIcon category="tram" />,
  bus: <VehicleIcon category="bus" />,
  mobilis: <VehicleIcon category="mobilis" />,
  "tram-outdated": <VehicleIcon category="tram" outdated />,
  "bus-outdated": <VehicleIcon category="bus" outdated />,
  "mobilis-outdated": <VehicleIcon category="mobilis" outdated />,
  "tram-arrow": <VehicleIcon category="tram" arrow />,
  "bus-arrow": <VehicleIcon category="bus" arrow />,
  "mobilis-arrow": <VehicleIcon category="mobilis" arrow />,
  "tram-arrow-outdated": <VehicleIcon category="tram" arrow outdated />,
  "bus-arrow-outdated": <VehicleIcon category="bus" arrow outdated />,
  "mobilis-arrow-outdated": <VehicleIcon category="mobilis" arrow outdated />,
  "tram-selection": <SelectionIcon category="tram" />,
  "bus-selection": <SelectionIcon category="bus" />,
  "mobilis-selection": <SelectionIcon category="mobilis" />,
};

export function useMapIcons() {
  useMapImage("tram", icons.tram);
  useMapImage("bus", icons.bus);
  useMapImage("mobilis", icons.mobilis);
  useMapImage("tram-outdated", icons["tram-outdated"]);
  useMapImage("bus-outdated", icons["bus-outdated"]);
  useMapImage("mobilis-outdated", icons["mobilis-outdated"]);
  useMapImage("tram-arrow", icons["tram-arrow"]);
  useMapImage("bus-arrow", icons["bus-arrow"]);
  useMapImage("mobilis-arrow", icons["mobilis-arrow"]);
  useMapImage("tram-arrow-outdated", icons["tram-arrow-outdated"]);
  useMapImage("bus-arrow-outdated", icons["bus-arrow-outdated"]);
  useMapImage("mobilis-arrow-outdated", icons["mobilis-arrow-outdated"]);
  useMapImage("tram-selection", icons["tram-selection"]);
  useMapImage("bus-selection", icons["bus-selection"]);
  useMapImage("mobilis-selection", icons["mobilis-selection"]);
}
