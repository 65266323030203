import {
  Block,
  Service,
  Shape,
  Stop,
  Variant,
  useRoute,
} from "../hooks/useRoute";
import { Link, useParams } from "react-router-dom";

import Alert from "react-bootstrap/Alert";
import { BlocksList } from "../components/BlocksList";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { ServiceSelect } from "../components/ServiceSelect";
import Spinner from "react-bootstrap/Spinner";
import { StopsList } from "../components/StopsList";
import { useState } from "react";
import { RouteMap } from "../components/RouteMap";
import { getUrlForRouteDepartures } from "../urls";
import { expandDepotName } from "../utils";

interface InnerProps {
  routeShortName: string;
  carrier: string | null;
  blocks: Block[];
  services: Service[];
  stops: Stop[];
  directions: string[];
  variants: Variant[];
  shapes: Shape[];
}

function Inner({
  routeShortName,
  carrier,
  blocks,
  services,
  stops,
  directions,
  variants,
  shapes,
}: InnerProps) {
  const [serviceId, setServiceId] = useState<string | undefined>(
    () =>
      (
        blocks.find((block) => block.vehicles_on_block.length >= 1) ||
        blocks.find((block) => block.is_current)
      )?.service_id // any active vehicle or any current block
  );

  return (
    <div className="row">
      <div className="col-lg-8 mb-3">
        {carrier !== null && (
          <p>
            Linia obsługiwana przez <strong>{carrier}</strong>
          </p>
        )}
        <h2 className="h4 mb-3">Rozkład jazdy</h2>
        <div className="mb-4">
          <ul className="list-unstyled">
            {variants.map((variant) => (
              <li key={variant.direction_id}>
                <Link
                  to={getUrlForRouteDepartures(
                    routeShortName,
                    variant.direction_id
                  )}
                >
                  <strong>
                    &rarr; {expandDepotName(variant.trip_headsigns[0])}
                  </strong>
                  {variant.trip_headsigns
                    .slice(1)
                    .map(
                      (trip_headsign) => ` / ${expandDepotName(trip_headsign)}`
                    )
                    .join("")}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <h2 className="h4 mb-3">Brygady</h2>
        <div className="mb-4">
          <ServiceSelect
            services={services}
            serviceId={serviceId}
            setServiceId={setServiceId}
            disabledFunc={(serviceId) =>
              !blocks.some((block) => block.service_id === serviceId)
            }
          />
        </div>
        <div className="mb-4">
          {serviceId !== undefined ? (
            <BlocksList
              blocks={blocks.filter((block) => block.service_id === serviceId)}
            />
          ) : blocks.length > 0 ? (
            <Alert variant="secondary">Wybierz dzień tygodnia.</Alert>
          ) : (
            <Alert variant="warning">Brak brygad.</Alert>
          )}
        </div>
        <div className="mb-3 mb-lg-0">
          <h2 className="h4 mb-3">Trasa</h2>
          <RouteMap stops={stops} shapes={shapes} />
        </div>
      </div>
      <div className="col-lg-4">
        <h2 className="h4 mb-3">Przystanki</h2>
        <StopsList stops={stops} directions={directions} />
      </div>
    </div>
  );
}

interface RouteInnerProps {
  routeShortName: string;
}

function RouteInner({ routeShortName }: RouteInnerProps) {
  const {
    carrier,
    blocks,
    services,
    stops,
    directions,
    variants,
    shapes,
    loading,
    error,
  } = useRoute(routeShortName);

  return (
    <Container className="pb-5">
      <PageTitle title={`Linia ${routeShortName}`} />
      <h1 className="my-4">Linia {routeShortName}</h1>
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Inner
          routeShortName={routeShortName}
          carrier={carrier}
          blocks={blocks}
          services={services}
          stops={stops}
          directions={directions}
          variants={variants}
          shapes={shapes}
        />
      )}
    </Container>
  );
}

export function Route() {
  const { routeShortName } = useParams();

  if (routeShortName === undefined) {
    return null;
  }

  return <RouteInner routeShortName={routeShortName} />;
}
