import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import {
  useVehiclesWithActiveTrips,
  VehicleWithActiveTrip,
} from "../hooks/useVehiclesWithActiveTrips";
import Table from "react-bootstrap/Table";
import { KmkId } from "../components/KmkId";
import Form from "react-bootstrap/Form";
import { useCallback, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { compareKmkId, compareRouteShortName } from "../utils";
import { Counter } from "../components/Counter";
import { StarToggle } from "../components/StarToggle";
import { useLocalStorage } from "usehooks-ts";
import { getUrlForBlock, getUrlForVehicle } from "../urls";
import { StatusIcon } from "../components/StatusIcon";
import { RouteShortNameBox } from "../components/RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "../components/RouteShortNameBoxPlaceholder";
import { StopLink } from "../components/StopLink";
import { ScrollHorizontallyIfNeeded } from "../components/ScrollHorizontallyIfNeeded";
import { useSecondsFromTimestamp } from "../hooks/useSecondsFromTimestamp";

type OrderKey = "kmk_id" | "route_short_name";

interface OrderLinkProps extends React.PropsWithChildren {
  orderKey: OrderKey;
  currentOrderKey: OrderKey;
  setOrderKey: (orderKey: OrderKey) => void;
}

function OrderLink({
  orderKey,
  currentOrderKey,
  setOrderKey,
  children,
}: OrderLinkProps) {
  const handleClick = useCallback(() => {
    setOrderKey(orderKey);
  }, [orderKey, setOrderKey]);

  return (
    <span className="link-primary" role="button" onClick={handleClick}>
      {children}
      {orderKey === currentOrderKey && " ↓"}
    </span>
  );
}

function searchVehiclesByKmkId(
  vehicles: VehicleWithActiveTrip[],
  query: string
) {
  const pattern = query.toLocaleUpperCase();
  return vehicles.filter(
    (vehicle) =>
      (vehicle.full_kmk_id ?? vehicle.kmk_id).includes(pattern) ||
      vehicle.short_model_name?.toLocaleUpperCase().includes(pattern) ||
      vehicle.full_model_name?.toLocaleUpperCase().includes(pattern)
  );
}

function isDateToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function getLatestActivityText(timestamp: number, seconds: number) {
  if (seconds <= 0) {
    return "teraz";
  }

  if (seconds < 60) {
    return `${seconds} s temu`;
  }

  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} min ${seconds % 60} s temu`;
  }

  const date = new Date(timestamp * 1000);
  const hourString = date.toLocaleTimeString().substring(0, 5);

  if (isDateToday(date)) {
    return `dzisiaj, ${hourString}`;
  }

  const dateString = date.toLocaleDateString();
  return `${dateString} ${hourString}`;
}

interface LatestActivityProps {
  timestamp: number;
  routeShortName: string | null;
  tripHeadsign: string | null;
}

function LatestActivity({ timestamp }: LatestActivityProps) {
  const seconds = useSecondsFromTimestamp(timestamp);

  return (
    <span className={seconds >= 7 * 60 ? "opacity-50 nobr" : "nobr"}>
      {getLatestActivityText(timestamp, seconds)}
    </span>
  );
}

function sortVehicles(vehicles: VehicleWithActiveTrip[], orderKey: OrderKey) {
  // TODO: unit tests
  const copy = Array.from(vehicles);
  if (orderKey === "kmk_id") {
    copy.sort((a, b) => compareKmkId(a.kmk_id, b.kmk_id));
  } else if (orderKey === "route_short_name") {
    copy.sort((a, b) =>
      compareRouteShortName(
        a.activeTrip?.route_short_name ?? " ",
        b.activeTrip?.route_short_name ?? " "
      )
    );
  }
  return copy;
}

interface VehiclesListProps {
  vehicles: VehicleWithActiveTrip[];
}

function VehiclesList({ vehicles }: VehiclesListProps) {
  window.localStorage.removeItem("vehicles_list_order");

  const [orderKey, setOrderKey] = useLocalStorage<OrderKey>(
    "vehicles_order_key",
    "kmk_id"
  );

  const sortedVehicles = useMemo(
    () => sortVehicles(vehicles, orderKey),
    [vehicles, orderKey]
  );

  return (
    <ScrollHorizontallyIfNeeded>
      <Table
        size="sm"
        striped
        bordered
        hover
        className="table-td-align-middle table-layout-fixed text-nowrap mb-0"
      >
        <thead>
          <tr>
            <th className="text-center" style={{ width: "18%" }}>
              <OrderLink
                orderKey="kmk_id"
                currentOrderKey={orderKey}
                setOrderKey={setOrderKey}
              >
                Numer taborowy
              </OrderLink>
            </th>
            <th className="text-center" style={{ width: "18%" }}>
              Model
            </th>
            <th className="text-center" style={{ width: "6%" }}>
              <OrderLink
                orderKey="route_short_name"
                currentOrderKey={orderKey}
                setOrderKey={setOrderKey}
              >
                Linia
              </OrderLink>
            </th>
            <th className="px-1">Kierunek</th>
            <th className="text-center" style={{ width: "16%" }}>
              Ostatnia aktywność
            </th>
            <th className="text-center" style={{ width: "18%" }}>
              Brygada
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedVehicles.map((vehicle) => (
            <tr key={vehicle.kmk_id}>
              <td className="text-center">
                <KmkId kmk_id={vehicle.full_kmk_id ?? vehicle.kmk_id} />
                <StarToggle kmkId={vehicle.kmk_id} />
              </td>
              <td
                className="text-center text-truncate px-1 small"
                title={vehicle.full_model_name ?? undefined}
              >
                {vehicle.full_model_name}
              </td>
              <td className="text-center">
                {vehicle.activeTrip !== null &&
                vehicle.activeTrip.route_short_name !== null ? (
                  <RouteShortNameBox
                    routeShortName={vehicle.activeTrip.route_short_name}
                  />
                ) : (
                  <RouteShortNameBoxPlaceholder />
                )}
              </td>
              <td className="px-1 text-truncate">
                {vehicle.activeTrip !== null &&
                  vehicle.activeTrip.trip_headsign !== null && (
                    <StopLink
                      stopName={vehicle.activeTrip.trip_headsign}
                      bold
                      removeNz
                      expandDepotName
                    />
                  )}
              </td>
              <td className="text-center">
                {vehicle.activeTrip !== null && (
                  <>
                    <StatusIcon timestamp={vehicle.activeTrip.timestamp} />{" "}
                    <LatestActivity
                      timestamp={vehicle.activeTrip.timestamp}
                      routeShortName={vehicle.activeTrip.route_short_name}
                      tripHeadsign={vehicle.activeTrip.trip_headsign}
                    />
                  </>
                )}
              </td>
              <td className="text-center">
                {vehicle.activeTrip !== null &&
                  vehicle.activeTrip.service_id !== null &&
                  vehicle.activeTrip.block_id !== null && (
                    <Link
                      to={getUrlForBlock(
                        vehicle.category,
                        vehicle.activeTrip.service_id,
                        vehicle.activeTrip.block_id
                      )}
                      className="bold"
                    >
                      {vehicle.category}/{vehicle.activeTrip.service_id}/
                      {vehicle.activeTrip.block_id}
                    </Link>
                  )}
              </td>
            </tr>
          ))}
          {vehicles.length === 0 && (
            <tr>
              <td colSpan={6} className="py-3 text-center text-secondary">
                Brak pojazdów.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollHorizontallyIfNeeded>
  );
}

export function Vehicles() {
  const { timestamp, vehiclesWithActiveTrips, loading, error } =
    useVehiclesWithActiveTrips();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get("q") ?? "";

  const [showOnlyActive, setShowOnlyActive] = useLocalStorage<boolean>(
    "show_only_active_vehicles",
    false
  );

  const [showOnlyFavorite, setShowOnlyFavorite] = useLocalStorage<boolean>(
    "show_only_favorite_vehicles",
    false
  );

  const { isFavorite } = useFavoriteVehicles();

  const filteredVehicles = useMemo(() => {
    let results = vehiclesWithActiveTrips;
    if (showOnlyActive && query.length === 0) {
      results = results.filter((v) => v.activeTrip !== null);
    }
    if (showOnlyFavorite && query.length === 0) {
      results = results.filter(
        (v) => v.kmk_id !== null && isFavorite(v.kmk_id)
      );
    }
    results = searchVehiclesByKmkId(results, query);
    return results;
  }, [
    vehiclesWithActiveTrips,
    query,
    showOnlyActive,
    showOnlyFavorite,
    isFavorite,
  ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && filteredVehicles.length === 1) {
        const vehicle = filteredVehicles[0];
        navigate(getUrlForVehicle(vehicle.full_kmk_id ?? vehicle.kmk_id));
      }
    },
    [filteredVehicles, navigate]
  );

  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const query = e.target.value;
      setSearchParams(query !== "" ? { q: query } : {}, { replace: true });
    },
    [setSearchParams]
  );

  return (
    <Container className="pb-5">
      <PageTitle title="Baza pojazdów" />
      <h1 className="my-4">Baza pojazdów</h1>
      <Form.Group className="mb-1" controlId="showOnlyActive">
        <Form.Check
          type="checkbox"
          label="Wyświetl tylko aktywne"
          checked={showOnlyActive}
          disabled={query.length > 0}
          onChange={(e) => setShowOnlyActive(e.target.checked)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="showOnlyFavorite">
        <Form.Check
          type="checkbox"
          label="Wyświetl tylko ulubione"
          checked={showOnlyFavorite}
          disabled={query.length > 0}
          onChange={(e) => setShowOnlyFavorite(e.target.checked)}
        />
      </Form.Group>
      <div className="mb-3 col-lg-3">
        <Form.Control
          type="text"
          placeholder="Wpisz numer taborowy..."
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {timestamp && (
        <p className="mb-3">
          Ostatnia aktualizacja: <Counter timestamp={timestamp / 1000} />
        </p>
      )}
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <VehiclesList vehicles={filteredVehicles} />
      )}
    </Container>
  );
}
