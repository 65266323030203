import Map, {
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  Layer,
  Marker,
  NavigationControl,
  ScaleControl,
  Source,
  useMap,
} from "react-map-gl/maplibre";
import { useEffect, useMemo } from "react";

import { Path } from "../hooks/useVehicle";
import { featureCollection, lineString, point } from "@turf/helpers";

const TTSS_COLOR = "orange";
const GTFS_COLOR = "limegreen";
const KOKON_COLOR = "blue";

interface RecenterAutomaticallyProps {
  latitude: number;
  longitude: number;
}

function RecenterAutomatically({
  latitude,
  longitude,
}: RecenterAutomaticallyProps) {
  const map = useMap();

  useEffect(() => {
    map.current?.flyTo({ center: [longitude, latitude] });
  }, [map, latitude, longitude]);

  return null;
}

interface PathPolylineProps {
  path: Path;
}

function PathPolyline({ path }: PathPolylineProps) {
  const geojson = useMemo(
    () =>
      lineString(
        path.map(({ latitude, longitude }) => [longitude, latitude]),
        path
      ),
    [path]
  );

  return (
    <Source id="path" type="geojson" data={geojson}>
      <Layer
        id="path"
        type="line"
        paint={{
          "line-color": "blue",
          "line-width": 4,
          "line-opacity": 0.5,
        }}
      />
    </Source>
  );
}

interface StopMarkersProps {
  stops: Stop[];
}

function StopMarkers({ stops }: StopMarkersProps) {
  const geojson = useMemo(
    () =>
      featureCollection(
        stops
          .filter((s) => s.stop_lat !== null && s.stop_lon !== null)
          .map((s) => point([s.stop_lon!, s.stop_lat!], s))
      ),
    [stops]
  );

  return (
    <Source id="stops" type="geojson" data={geojson}>
      <Layer
        id="stops_circles"
        type="circle"
        paint={{
          "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 1, 15, 3],
          "circle-color": "black",
          "circle-opacity": 0.5,
        }}
      />
      <Layer
        id="stops_labels"
        type="symbol"
        layout={{
          "text-field": [
            "concat",
            ["get", "stop_name"],
            " ",
            ["get", "stop_num"],
          ],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "bottom",
          "text-offset": [0, -0.5],
        }}
        paint={{
          "text-color": "gray",
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

interface VehicleMarkerProps {
  latitude: number | null;
  longitude: number | null;
  bearing: number | null;
  color: string;
  zIndex: number;
}

function VehicleMarker({
  latitude,
  longitude,
  bearing,
  color,
  zIndex,
}: VehicleMarkerProps) {
  if (latitude === null || longitude === null) {
    return null;
  }

  return (
    <Marker latitude={latitude} longitude={longitude}>
      <svg
        width={40}
        height={40}
        style={{ rotate: `${bearing ?? 0}deg`, zIndex }}
      >
        <circle
          cx={20}
          cy={20}
          r={10}
          stroke={color}
          strokeWidth="1"
          fill="white"
        />
        <circle cx={20} cy={20} r={7} fill={color} />
        {bearing !== null && <polygon points="20,0 30,7 10,7" fill={color} />}
      </svg>
    </Marker>
  );
}

interface Stop {
  stop_lat: number | null;
  stop_lon: number | null;
  stop_name: string;
}

interface VehicleMapProps {
  ttssLatitude: number | null;
  ttssLongitude: number | null;
  ttssBearing: number | null;
  ttssTimestamp: number | null;
  gtfsLatitude: number | null;
  gtfsLongitude: number | null;
  gtfsBearing: number | null;
  gtfsTimestamp: number | null;
  kokonLatitude: number | null;
  kokonLongitude: number | null;
  kokonBearing: number | null;
  kokonTimestamp: number | null;
  path?: Path | null;
  stops?: Stop[] | null;
}

export function VehicleMap({
  ttssLatitude,
  ttssLongitude,
  ttssBearing,
  ttssTimestamp,
  gtfsLatitude,
  gtfsLongitude,
  gtfsBearing,
  gtfsTimestamp,
  kokonLatitude,
  kokonLongitude,
  kokonBearing,
  kokonTimestamp,
  path,
  stops,
}: VehicleMapProps) {
  const center = useMemo(() => {
    return ttssTimestamp !== null
      ? ([ttssLongitude, ttssLatitude] as [number, number])
      : (gtfsTimestamp ?? 1) >= (kokonTimestamp ?? 0) &&
        gtfsLatitude !== null &&
        gtfsLongitude !== null
      ? ([gtfsLongitude, gtfsLatitude] as [number, number])
      : kokonLatitude !== null && kokonLongitude !== null
      ? ([kokonLongitude, kokonLatitude] as [number, number])
      : undefined;
  }, [
    ttssLatitude,
    ttssLongitude,
    ttssTimestamp,
    gtfsLatitude,
    gtfsLongitude,
    gtfsTimestamp,
    kokonLatitude,
    kokonLongitude,
    kokonTimestamp,
  ]);

  return (
    <div style={{ aspectRatio: "8 / 7" }}>
      <Map
        initialViewState={{
          longitude: center?.[0],
          latitude: center?.[1],
          zoom: 14,
        }}
        minZoom={9}
        bearing={0}
        pitchWithRotate={false}
        attributionControl={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
      >
        {center !== undefined && (
          <RecenterAutomatically longitude={center[0]} latitude={center[1]} />
        )}
        {path && <PathPolyline path={path} />}
        {stops && <StopMarkers stops={stops} />}
        <VehicleMarker
          latitude={kokonLatitude}
          longitude={kokonLongitude}
          bearing={kokonBearing}
          color={KOKON_COLOR}
          zIndex={200}
        />
        <VehicleMarker
          latitude={gtfsLatitude}
          longitude={gtfsLongitude}
          bearing={gtfsBearing}
          color={GTFS_COLOR}
          zIndex={300}
        />
        <VehicleMarker
          latitude={ttssLatitude}
          longitude={ttssLongitude}
          bearing={ttssBearing}
          color={TTSS_COLOR}
          zIndex={400}
        />
        <NavigationControl />
        <GeolocateControl />
        <FullscreenControl />
        <ScaleControl />
        <AttributionControl compact={false} />
      </Map>
    </div>
  );
}
