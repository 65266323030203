import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";
import { useCacheBuster } from "./useCacheBuster";

export type Category = "tram" | "bus" | "mobilis";

export type Source = "gtfs" | "kokon" | "ttss" | "mobilis";

export interface Vehicle {
  kmk_id: string | null;
  full_kmk_id: string | null;
  category: Category;
  block_id: string | null;
  trip_id: string | null;
  service_id: string | null;
  shift: string | null;
  route_short_name: string | null;
  trip_headsign: string | null;
  stop_name: string | null;
  latitude: number | null;
  longitude: number | null;
  bearing: number | null;
  timestamp: number | null;
  source: Source;
  key: string;
  ttss_vehicle_id?: string;
}

function useFetchVehicles(source: Source, interval: number) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{ vehicles: Vehicle[] }>(
    `${BACKEND_BASE_URL}/api/vehicles/active/${source}?t=${cacheBuster}`
  );

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setVehicles(data.vehicles);
      setTimestamp(Date.now());
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when source changes
    setVehicles([]);
    setTimestamp(null);
    setLoading(true);
  }, [source]);

  return { timestamp, vehicles, loading, error };
}

export function useActiveVehicles(source: Source, interval: number) {
  const { timestamp, vehicles, loading, error } = useFetchVehicles(
    source,
    interval
  );

  const [localStorage, setLocalStorage] = useLocalStorage<{
    vehicles: Vehicle[];
    timestamp: number;
  } | null>(`vehicles_${source}`, null);

  // update local storage after fetch
  useEffect(() => {
    if (!loading && timestamp) {
      setLocalStorage({ vehicles, timestamp });
    }
  }, [timestamp, loading, setLocalStorage, vehicles]);

  if (
    loading &&
    localStorage !== null &&
    Date.now() - localStorage.timestamp < 2 * 60_000
  ) {
    return { ...localStorage, loading: false, error: undefined };
  }

  return { vehicles, timestamp, loading, error };
}
