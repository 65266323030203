import { useMemo } from "react";
import { BACKEND_BASE_URL } from "../config";
import { useFetch } from "usehooks-ts";

export type Source = "gtfs" | "mobilis" | "ttss" | "kokon";

export type Category = "tram" | "bus" | "mobilis";

export type Vehicle = {
  source: Source;
  category: Category;
  trip_id: string | null;
  route_short_name: string | null;
  trip_headsign: string | null;
};

export interface Point {
  latitude: number;
  longitude: number;
}

export type Path = Point[];

export function useVehiclePath(vehicle: Vehicle) {
  const url = useMemo(() => {
    switch (vehicle.source) {
      case "gtfs":
      case "mobilis":
        return vehicle.trip_id !== null
          ? `${BACKEND_BASE_URL}/api/gtfs/paths?` +
              new URLSearchParams({
                category: vehicle.category,
                trip_id: vehicle.trip_id,
              })
          : undefined;
      case "ttss":
      case "kokon":
        return vehicle.route_short_name !== null &&
          vehicle.trip_headsign !== null
          ? `${BACKEND_BASE_URL}/api/ttss/paths?` +
              new URLSearchParams({
                category: vehicle.category,
                route_short_name: vehicle.route_short_name,
                trip_headsign: vehicle.trip_headsign,
              })
          : undefined;
    }
  }, [vehicle]);

  const { data, error } = useFetch<{ path: Path }>(url);

  return {
    path: data?.path ?? [],
    loading: data === undefined,
    error,
  };
}
