import { useMemo } from "react";
import { useActiveVehicles } from "./useActiveVehicles";
import { Block, useBlocks } from "./useBlocks";

export type { Service } from "./useBlocks";

export interface VehicleOnBlock {
  kmk_id: string;
  timestamp: number;
}

export interface BlockWithActiveVehicles extends Block {
  vehicles_on_block: VehicleOnBlock[];
}

export function useBlocksWithActiveVehicles() {
  const {
    blocks,
    servicesMpk,
    servicesMobilis,
    loading: blocksLoading,
    error: blocksError,
  } = useBlocks(60_000);

  const {
    timestamp,
    vehicles,
    loading: vehiclesLoading,
    error: vehiclesError,
  } = useActiveVehicles("gtfs", 10_000);

  const blocksWithActiveVehicles = useMemo(() => {
    const vehiclesOnBlocks = new Map<string, VehicleOnBlock[]>();

    vehicles.forEach((v) => {
      if (v.block_id !== null && v.kmk_id !== null && v.timestamp !== null) {
        const key = `${v.category}_${v.block_id}`;
        const array = vehiclesOnBlocks.get(key) ?? [];
        array.push({ kmk_id: v.kmk_id, timestamp: v.timestamp });
        vehiclesOnBlocks.set(key, array);
      }
    });

    return blocks.map((block) => ({
      ...block,
      vehicles_on_block:
        vehiclesOnBlocks.get(`${block.category}_${block.block_id}`) ?? [],
    }));
  }, [blocks, vehicles]);

  const loading = blocksLoading || vehiclesLoading;

  const error = blocksError ?? vehiclesError ?? null;

  return {
    timestamp,
    blocksWithActiveVehicles,
    servicesMpk,
    servicesMobilis,
    loading,
    error,
  };
}
