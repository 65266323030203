import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";

export interface Stop {
  stop_name: string;
  stop_num: string | null;
  stop_lat: number | null;
  stop_lon: number | null;
  tram: boolean;
  bus: boolean;
}

function sortStopsByName(original: Stop[]) {
  const copy = Array.from(original);
  copy.sort((a, b) => a.stop_name.localeCompare(b.stop_name));
  return copy;
}

function useFetchStops() {
  const { data, error } = useFetch<{ stops: Stop[] }>(
    `${BACKEND_BASE_URL}/api/stops`
  );

  const [stops, setStops] = useState<Stop[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStops(sortStopsByName(data.stops));
      setLoading(false);
    }
  }, [data]);

  return { stops, loading, error };
}

export function useStops() {
  const { stops, loading, error } = useFetchStops();

  window.localStorage.removeItem("stops");
  window.localStorage.removeItem("stops_v2");
  window.localStorage.removeItem("stops_v3");

  const [localStorage, setLocalStorage] = useLocalStorage<Stop[] | null>(
    "stops_v4",
    null
  );

  useEffect(() => {
    if (!loading) {
      setLocalStorage(stops);
    }
  }, [loading, setLocalStorage, stops]);

  if (loading && localStorage !== null) {
    return { stops: localStorage, loading: false };
  }

  return { stops, loading, error };
}
