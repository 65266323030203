import { Category, useBlock } from "../hooks/useBlock";
import { getCarrierForCategory, isValidCategory } from "../utils";

import { BlockTrips } from "../components/BlockTrips";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

function getWeekdaysForServiceId(serviceId: string) {
  console.log(serviceId.split("_")[1]);
  switch (serviceId.split("_")[1]) {
    case "PO":
      return "poniedziałki, wtorki i środy";
    case "CZ":
      return "czwartki";
    case "PT":
      return "piątki";
    case "SO":
      return "soboty";
    case "SW":
      return "niedziele i święta";
    default:
      return null;
  }
}

interface BlockInnerProps {
  category: Category;
  serviceId: string;
  blockId: string;
}

function BlockInner({ category, serviceId, blockId }: BlockInnerProps) {
  const { trips, loading, error } = useBlock(category, serviceId, blockId);

  const title =
    category === "mobilis" ? `Brygada ${blockId}` : "Rozkład brygady";

  const carrier = getCarrierForCategory(category);

  const weekdays = getWeekdaysForServiceId(serviceId);

  return (
    <Container className="pb-5">
      <PageTitle title={title} />
      <h1 className="my-4">{title}</h1>
      <div className="mb-4">
        {carrier !== null && (
          <p className="mb-1">
            Brygada obsługiwana przez <strong>{carrier}</strong>
          </p>
        )}
        {weekdays !== null && (
          <p className="mb-1">
            Rozkład obowiązuje w <strong>{weekdays}</strong>
          </p>
        )}
      </div>
      {error?.message === "NOT FOUND" ? (
        <Container className="g-sm-0">
          <Alert variant="secondary">Nie znaleziono brygady.</Alert>
        </Container>
      ) : error ? (
        <Error error={error} />
      ) : (
        <BlockTrips
          category={category}
          serviceId={serviceId}
          blockId={blockId}
          trips={trips}
          loading={loading}
        />
      )}
    </Container>
  );
}

export function Block() {
  const { category, serviceId, blockId } = useParams();

  if (category === undefined || !isValidCategory(category)) {
    return null;
  }
  if (serviceId === undefined) {
    return null;
  }
  if (blockId === undefined) {
    return null;
  }

  return (
    <BlockInner
      category={category as Category}
      serviceId={serviceId}
      blockId={blockId}
    />
  );
}
