import { useVehicle } from "../hooks/useVehicle";
import { Fragment, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Counter } from "../components/Counter";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { RouteShortNameBox } from "../components/RouteShortNameBox";
import Spinner from "react-bootstrap/Spinner";
import { StopLink } from "../components/StopLink";
import { TripStopTimes } from "../components/TripStopTimes";
import { VehicleMap } from "../components/VehicleMap";
import { getCarrierForVehicleKmkId, isValidKmkId } from "../utils";
import { FloorIcon } from "../components/FloorIcon";
import {
  getUrlForBlock,
  getUrlForFotozajezdnia,
  getUrlForTrip,
  getUrlForVehicle,
  getUrlForVehicleOnMap,
} from "../urls";
import { StatusIcon } from "../components/StatusIcon";
import { APP_NAME } from "../config";

interface FullKmkIdProps {
  kmkId: string;
}

function FullKmkId({ kmkId }: FullKmkIdProps) {
  return (
    <>
      {kmkId.split("+").map((part, i) => (
        <Fragment key={i}>
          {i !== 0 && "+"}
          <small>{part.substring(0, 2)}</small>
          {part.substring(2, 5)}
        </Fragment>
      ))}
    </>
  );
}

interface VehicleButtonsProps {
  kmkId: string;
}

function VehicleButtons({ kmkId }: VehicleButtonsProps) {
  return (
    <>
      {/* <Link to={getUrlForVehicleHistory(kmkId)} className="me-2">
        <Button variant="outline-primary" className="mb-2">
          Historia pojazdu
        </Button>
      </Link> */}
      <a href={getUrlForFotozajezdnia(kmkId)} target="_blank" rel="noreferrer">
        <Button variant="outline-primary" className="mb-2">
          Zdjęcia na Fotozajezdnia.pl
        </Button>
      </a>
    </>
  );
}

async function shareVehicle(kmkId: string) {
  try {
    await navigator.share({
      title: `${kmkId} – ${APP_NAME}`,
      url: getUrlForVehicle(kmkId),
    });
  } catch (e) {
    // Do nothing
  }
}

export interface VehicleInnerProps {
  kmkId: string;
}

export function VehicleInner({ kmkId }: VehicleInnerProps) {
  const { vehicle, loading, error } = useVehicle(kmkId, 10_000);

  const navigate = useNavigate();

  useEffect(() => {
    if (vehicle !== null && kmkId !== vehicle.full_kmk_id) {
      navigate(getUrlForVehicle(vehicle.full_kmk_id), { replace: true });
    }
  }, [vehicle, kmkId, navigate]);

  const operator = getCarrierForVehicleKmkId(kmkId);

  return (
    <Container>
      <PageTitle title={vehicle?.full_kmk_id ?? kmkId} />
      <h1 className="my-4">
        <FullKmkId kmkId={vehicle?.full_kmk_id ?? kmkId} />
      </h1>
      {error ? (
        <>
          <Error error={error} />
          <VehicleButtons kmkId={kmkId} />
        </>
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : vehicle === null ? (
        <VehicleButtons kmkId={kmkId} />
      ) : (
        <div className="row">
          <div className="mb-4 col-lg-6">
            <div className="mb-4">
              <div className="mb-3">
                {vehicle.full_model_name !== null && (
                  <div className="mb-1">
                    <strong>{vehicle.full_model_name}</strong>{" "}
                    {vehicle.floor && <FloorIcon floor={vehicle.floor} />}
                  </div>
                )}
                {operator !== null && (
                  <div className="mb-1">
                    {vehicle.category === "tram" ? "Tramwaj" : "Autobus"}{" "}
                    <span className="bold">{operator}</span>
                  </div>
                )}
              </div>
              <VehicleButtons kmkId={kmkId} />
            </div>
            <div className="mb-4">
              <h3 className="h4 mb-3">
                TTSS&ensp;
                {vehicle.ttss_timestamp !== null && (
                  <span className="fs-6 text-muted">
                    <StatusIcon
                      timestamp={vehicle.ttss_timestamp}
                      style={{ marginBottom: 1, marginRight: 7 }}
                    />
                    <Counter timestamp={vehicle.ttss_timestamp} />
                  </span>
                )}
              </h3>
              {vehicle.ttss_route_short_name !== null &&
              vehicle.ttss_trip_headsign !== null ? (
                <div className="my-3">
                  <RouteShortNameBox
                    routeShortName={vehicle.ttss_route_short_name}
                  />
                  &nbsp;
                  <StopLink
                    stopName={vehicle.ttss_trip_headsign}
                    bold
                    removeNz
                    expandDepotName
                  />
                </div>
              ) : (
                <div className="text-muted">❔ Brak danych</div>
              )}
            </div>
            {/* <div className="mb-4">
              <h3 className="h4 mb-3">
                Kokon&ensp;
                {vehicle.kokon_timestamp !== null && (
                  <span className="fs-6 text-muted">
                    <StatusIcon
                      timestamp={vehicle.kokon_timestamp}
                      style={{ marginBottom: 1, marginRight: 7 }}
                    />
                    <Counter timestamp={vehicle.kokon_timestamp} />
                  </span>
                )}
              </h3>
              {vehicle.kokon_route_short_name !== null &&
              vehicle.kokon_trip_headsign !== null &&
              vehicle.kokon_current_stop_name !== null ? (
                <div className="my-3">
                  <RouteShortNameBox
                    routeShortName={vehicle.kokon_route_short_name}
                  />
                  &nbsp;
                  <StopLink
                    stopName={vehicle.kokon_trip_headsign}
                    bold
                    removeNz
                    expandDepotName
                  />
                  <div className="my-2">
                    Aktualny przystanek:{" "}
                    <StopLink stopName={vehicle.kokon_current_stop_name} />
                  </div>
                </div>
              ) : (
                <div className="text-muted">❔ Brak danych</div>
              )}
            </div> */}
            <div className="mb-4">
              <h3 className="h4 mb-3">
                GTFS Realtime&ensp;
                {vehicle.gtfs_timestamp !== null && (
                  <span className="fs-6 text-muted">
                    <StatusIcon
                      timestamp={vehicle.gtfs_timestamp}
                      style={{ marginBottom: 1, marginRight: 7 }}
                    />
                    <Counter timestamp={vehicle.gtfs_timestamp} />
                  </span>
                )}
              </h3>
              {vehicle.gtfs_route_short_name !== null ||
              vehicle.gtfs_trip_headsign !== null ||
              vehicle.gtfs_current_stop_name !== null ? (
                <div className="my-3">
                  {(vehicle.gtfs_route_short_name !== null ||
                    vehicle.gtfs_trip_headsign !== null) && (
                    <div className="my-2">
                      {vehicle.gtfs_trip_headsign === null && (
                        <strong>Linia&nbsp;</strong>
                      )}
                      {vehicle.gtfs_route_short_name !== null && (
                        <RouteShortNameBox
                          routeShortName={vehicle.gtfs_route_short_name}
                        />
                      )}
                      &nbsp;
                      {vehicle.gtfs_trip_headsign !== null && (
                        <StopLink
                          stopName={vehicle.gtfs_trip_headsign}
                          bold
                          removeNz
                          expandDepotName
                        />
                      )}
                    </div>
                  )}
                  {vehicle.gtfs_current_stop_name !== null && (
                    <div className="my-2">
                      Aktualny przystanek:{" "}
                      <StopLink stopName={vehicle.gtfs_current_stop_name} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-muted mb-3">❔ Brak danych</div>
              )}
              {vehicle.category !== null &&
                vehicle.gtfs_service_id !== null &&
                vehicle.gtfs_block_id !== null &&
                vehicle.gtfs_trip_id !== null && (
                  <div className="mb-2">
                    <Link
                      to={getUrlForBlock(
                        vehicle.category,
                        vehicle.gtfs_service_id,
                        vehicle.gtfs_block_id
                      )}
                      className="me-2"
                    >
                      <Button variant="outline-primary" className="mb-2">
                        Rozkład brygady{" "}
                        {vehicle.category === "mobilis" && (
                          <strong>{vehicle.gtfs_block_id}</strong>
                        )}
                      </Button>
                    </Link>
                    <Link
                      to={getUrlForTrip(
                        vehicle.category,
                        vehicle.gtfs_service_id,
                        vehicle.gtfs_block_id,
                        vehicle.gtfs_trip_id
                      )}
                      className="me-2"
                    >
                      <Button variant="outline-primary" className="mb-2">
                        Rozkład kursu
                      </Button>
                    </Link>
                  </div>
                )}
              {vehicle.gtfs_stop_times && vehicle.gtfs_stop_times.length > 0 && (
                <div className="mb-3">
                  <TripStopTimes
                    stopTimes={vehicle.gtfs_stop_times}
                    currentStopSequence={vehicle.gtfs_current_stop_sequence}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mb-3 col-lg-6">
            <div className="px-lg-5">
              <VehicleMap
                ttssLatitude={vehicle.ttss_latitude}
                ttssLongitude={vehicle.ttss_longitude}
                ttssBearing={vehicle.ttss_bearing}
                ttssTimestamp={vehicle.ttss_timestamp}
                gtfsLatitude={vehicle.gtfs_latitude}
                gtfsLongitude={vehicle.gtfs_longitude}
                gtfsBearing={vehicle.gtfs_bearing}
                gtfsTimestamp={vehicle.gtfs_timestamp}
                kokonLatitude={vehicle.kokon_latitude}
                kokonLongitude={vehicle.kokon_longitude}
                kokonBearing={vehicle.kokon_bearing}
                kokonTimestamp={vehicle.kokon_timestamp}
                path={vehicle.gtfs_path}
                stops={vehicle.gtfs_stop_times}
              />
              <div className="pt-2 small">
                {vehicle.ttss_latitude !== null ? "🟠" : "❔"} TTSS{" "}
                {vehicle.ttss_timestamp !== null && (
                  <span className="text-muted">
                    (
                    <Counter timestamp={vehicle.ttss_timestamp} />)
                  </span>
                )}
                <br />
                {vehicle.gtfs_latitude !== null ? "🟢" : "❔"} GTFS Realtime{" "}
                {vehicle.gtfs_timestamp !== null && (
                  <span className="text-muted">
                    (
                    <Counter timestamp={vehicle.gtfs_timestamp} />)
                  </span>
                )}
                <br />
                {/* {vehicle.kokon_latitude !== null ? "🔵" : "❔"} Kokon{" "}
                {vehicle.kokon_timestamp !== null && (
                  <span className="text-muted">
                    (
                    <Counter timestamp={vehicle.kokon_timestamp} />)
                  </span>
                )} */}
              </div>
              {(vehicle.ttss_latitude !== null ||
                vehicle.gtfs_latitude !== null ||
                vehicle.kokon_latitude !== null) && (
                <Link to={getUrlForVehicleOnMap(kmkId)}>
                  <Button variant="outline-primary" className="mt-3 mb-2 me-2">
                    Pokaż na mapie
                  </Button>
                </Link>
              )}
              {navigator.share !== undefined && (
                <Button
                  variant="outline-primary"
                  className="mt-3 mb-2"
                  onClick={() => shareVehicle(vehicle.full_kmk_id ?? kmkId)}
                >
                  Udostępnij
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export function Vehicle() {
  const { kmk_id } = useParams();

  if (kmk_id === undefined) {
    return null;
  }
  if (!isValidKmkId(kmk_id)) {
    return null;
  }

  return <VehicleInner kmkId={kmk_id} />;
}
