import { encodeStopName, joinStopNameAndNumber } from "./utils";

export function getUrlForHome() {
  return "/";
}

export function getUrlForMap() {
  return "/mapa";
}

export function getUrlForVehicleOnMap(kmkId: string) {
  return `/mapa/${kmkId}`;
}

export function getUrlForStopOnMap(stopName: string) {
  return `/mapa/${encodeStopName(stopName)}`;
}

export function getUrlForRoutes() {
  return "/linie";
}

export function getUrlForRoute(routeShortName: string) {
  return `/linie/${routeShortName}`;
}

export function getUrlForRouteDepartures(
  routeShortName: string,
  directionId: number
) {
  return `/linie/${routeShortName}/kierunki/${directionId}`;
}

export function getUrlForRouteDeparturesFromStop(
  routeShortName: string,
  directionId: number,
  stopName: string,
  stopNumber: string | null
) {
  const encodedStopName = encodeStopName(
    joinStopNameAndNumber(stopName, stopNumber)
  );
  return `/linie/${routeShortName}/kierunki/${directionId}/przystanki/${encodedStopName}`;
}

export function getUrlForVehiclesOnRoutes() {
  return "/pojazdy-na-liniach";
}

export function getUrlForVehicles() {
  return "/pojazdy";
}

export function getUrlForVehicle(kmkId: string) {
  return `/pojazdy/${kmkId}`;
}

export function getUrlForVehicleHistory(kmkId: string) {
  return `/pojazdy/${kmkId}/historia`;
}

export function getUrlForFotozajezdnia(kmkId: string) {
  const encodedKmkId = encodeURIComponent(`#${kmkId}`);
  return `https://fotozajezdnia.pl/search.php?search_keywords=${encodedKmkId}`;
}

export function getUrlForStops() {
  return "/przystanki";
}

type Mode =
  | "current_stop_times"
  | "departures"
  | "arrivals"
  | "planned"
  | "hidden";

export function getUrlForStop(stopName: string, mode: Mode | null = null) {
  return mode === null
    ? `/przystanki/${encodeStopName(stopName)}`
    : `/przystanki/${encodeStopName(stopName)}/${mode}`;
}

export function getUrlForStopWithNumber(stopName: string, stopNumber: string) {
  return getUrlForStop(joinStopNameAndNumber(stopName, stopNumber));
}

type Carrier = "mpk" | "mobilis";

export function getUrlForBlocks(carrier: Carrier) {
  return `/brygady/${carrier}`;
}

export function getUrlForBlock(
  // TODO: use Category type
  category: string,
  service_id: string,
  block_id: string
) {
  return `/brygady/${category}/${service_id}/${block_id}`;
}

export function getUrlForTrip(
  category: string,
  serviceId: string,
  blockId: string,
  tripId: string
) {
  return `/brygady/${category}/${serviceId}/${blockId}/kursy/${tripId}`;
}

export function getUrlForHiddenTrips() {
  return "/kursy-zjazdowe-i-wyjazdowe";
}

export function getUrlForStatus() {
  return "/status";
}

export function getUrlForAbout() {
  return "/informacje";
}

export function getUrlForPrivacyPolicy() {
  return "/polityka-prywatnosci";
}
