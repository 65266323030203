import { useEffect, useMemo, useRef } from "react";
import Map, {
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl/maplibre";
import { featureCollection, lineString, point } from "@turf/helpers";
import bbox from "@turf/bbox";
import { Shape } from "../hooks/useTrip";

interface Stop {
  stop_name: string;
  stop_num: string | null;
  stop_lat: number | null;
  stop_lon: number | null;
}

interface StopMarkersProps {
  stops: Stop[];
}

function geojsonFromStops(stops: Stop[]) {
  return featureCollection(
    stops
      .filter((s) => s.stop_lat !== null && s.stop_lon !== null)
      .map((s) => point([s.stop_lon!, s.stop_lat!], s))
  );
}

function StopMarkers({ stops }: StopMarkersProps) {
  const geojson = useMemo(() => geojsonFromStops(stops), [stops]);

  return (
    <Source id="stops" type="geojson" data={geojson}>
      <Layer
        id="stops_circles"
        type="circle"
        paint={{
          "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 1, 15, 3],
          "circle-color": "black",
          "circle-opacity": 0.5,
        }}
      />
      <Layer
        id="stops_labels"
        type="symbol"
        layout={{
          "text-field": ["get", "stop_name"],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "bottom",
          "text-offset": [0, -0.5],
        }}
        paint={{
          "text-color": "gray",
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

interface ShapeLineProps {
  shape: Shape;
}

function geojsonFromShape(shape: Shape) {
  return lineString(
    shape.map(({ latitude, longitude }) => [longitude, latitude]),
    shape
  );
}

function ShapeLine({ shape }: ShapeLineProps) {
  const geojson = useMemo(() => geojsonFromShape(shape), [shape]);

  return (
    <Source id="shape" type="geojson" data={geojson}>
      <Layer
        id="shape_line"
        type="line"
        paint={{
          "line-color": "blue",
          "line-width": 3,
          "line-opacity": 0.3,
        }}
      />
    </Source>
  );
}

interface TripMapProps {
  stops: Stop[];
  shape: Shape | null;
}

const padding = 100;

export function TripMap({ stops, shape }: TripMapProps) {
  const mapRef = useRef(null);

  const bounds = useMemo(() => {
    const geojson =
      shape !== null ? geojsonFromShape(shape) : geojsonFromStops(stops);
    return bbox(geojson) as [number, number, number, number];
  }, [stops, shape]);

  useEffect(() => {
    // @ts-expect-error
    mapRef.current?.fitBounds(bounds, { padding, duration: 500 });
  }, [bounds]);

  return (
    <div style={{ aspectRatio: 1 }}>
      <Map
        ref={mapRef}
        initialViewState={{ bounds, fitBoundsOptions: { padding } }}
        minZoom={9}
        bearing={0}
        pitchWithRotate={false}
        attributionControl={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
      >
        <StopMarkers stops={stops} />
        {shape && <ShapeLine shape={shape} />}
        <NavigationControl />
        <GeolocateControl />
        <FullscreenControl />
        <ScaleControl />
        <AttributionControl compact={false} />
      </Map>
    </div>
  );
}
