import "./KmkId.css";

import { Fragment } from "react";
import { Link } from "react-router-dom";
import { getUrlForVehicle } from "../urls";
import { isValidKmkId } from "../utils";

interface KmkIdProps {
  kmk_id: string;
  link?: boolean;
}

export function KmkId({ kmk_id, link = true }: KmkIdProps) {
  const inner = isValidKmkId(kmk_id)
    ? kmk_id.split("+").map((part, i) => (
        <Fragment key={i}>
          {i !== 0 && "+"}
          <small className="prefix">{part.substring(0, 2)}</small>
          {part.substring(2, 5)}
        </Fragment>
      ))
    : kmk_id;

  return link ? (
    <Link to={getUrlForVehicle(kmk_id)} className="hidden-link kmk-id">
      {inner}
    </Link>
  ) : (
    <>{inner}</>
  );
}
