import Container from "react-bootstrap/Container";
import { Mode } from "../hooks/useStopPassages";
import PageTitle from "../components/PageTitle";
import { PlannedStopPassages } from "./PlannedStopPassages";
import { StopModeButton } from "../components/StopModeButton";
import { StopPassages } from "../components/StopPassages";
import { StopsMap } from "../components/StopsMap";
import { decodeStopName, splitStopNameAndNumber } from "../utils";
import { Link, useParams } from "react-router-dom";
import { CurrentStopTimes } from "../components/CurrentStopTimes";
import { Button } from "react-bootstrap";
import { getUrlForStopOnMap } from "../urls";
import { FavoriteStopToggle } from "../components/FavoriteStopToggle";

export interface StopInnerProps {
  stopName: string;
  stopNumber: string | null;
  mode: Mode | "current_stop_times" | "planned" | "hidden";
}

export function StopInner({ stopName, stopNumber, mode }: StopInnerProps) {
  return (
    <Container className="g-0 g-sm-4 pb-5">
      <PageTitle title={stopName} />
      <Container className="my-3 my-sm-4 g-4 g-sm-0">
        <StopModeButton stopName={stopName} mode={mode} />
        <h1>
          {stopName}
          {stopNumber !== null && (
            <>
              &nbsp;<small>{stopNumber}</small>
            </>
          )}
          <FavoriteStopToggle stopName={stopName} stopNumber={stopNumber} />
        </h1>
      </Container>
      <div className="row gx-0 gx-sm-4">
        <div className="col-lg-4 mb-4">
          <StopsMap stopName={stopName} stopNumber={stopNumber} />
          <div className="d-none d-lg-block">
            <Link to={getUrlForStopOnMap(stopName)}>
              <Button variant="outline-primary" className="mt-3 mb-2">
                Pokaż na mapie
              </Button>
            </Link>
          </div>
        </div>
        <div className="col-lg-8 order-lg-first">
          {mode === "current_stop_times" ? (
            <CurrentStopTimes stopName={stopName} stopNumber={stopNumber} />
          ) : mode === "hidden" ? (
            <PlannedStopPassages stopName={stopName} onlyHidden />
          ) : mode === "planned" ? (
            <PlannedStopPassages stopName={stopName} />
          ) : (
            <StopPassages stopName={stopName} mode={mode} />
          )}
        </div>
      </div>
    </Container>
  );
}

export function Stop() {
  const { encodedStopName, mode } = useParams();

  if (encodedStopName === undefined) {
    return null;
  }

  if (
    !(
      mode === "current_stop_times" ||
      mode === "departures" ||
      mode === "arrivals" ||
      mode === "planned" ||
      mode === "hidden" ||
      mode === undefined
    )
  ) {
    return null;
  }

  const decodedStopName = decodeStopName(encodedStopName);

  const [stopName, stopNumber] = splitStopNameAndNumber(decodedStopName);

  if (
    stopNumber !== null &&
    mode !== "current_stop_times" &&
    mode !== undefined
  ) {
    return null;
  }

  return (
    <StopInner
      stopName={stopName}
      stopNumber={stopNumber}
      mode={mode ?? "current_stop_times"}
    />
  );
}
