import { LngLat, MapGeoJSONFeature } from "maplibre-gl";

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getDistanceBetweenCoords(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  const R = 6_371_000;
  const dLat = deg2rad(lat2 - lat1);
  const dLng = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

export function getNearestFeature(
  features: MapGeoJSONFeature[] | undefined,
  lngLat: LngLat
): MapGeoJSONFeature | null {
  if (!features || features.length === 0) {
    return null;
  }
  return features.reduce((prev, curr) =>
    getDistanceBetweenCoords(
      lngLat.lat,
      lngLat.lng,
      curr.properties.latitude,
      curr.properties.longitude
    ) <
    getDistanceBetweenCoords(
      lngLat.lat,
      lngLat.lng,
      prev.properties.latitude,
      prev.properties.longitude
    )
      ? curr
      : prev
  );
}
