import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  getUrlForAbout,
  getUrlForBlocks,
  getUrlForHiddenTrips,
  getUrlForHome,
  getUrlForMap,
  getUrlForRoutes,
  getUrlForStatus,
  getUrlForStops,
  getUrlForVehicles,
  getUrlForVehiclesOnRoutes,
} from "../urls";

export function Navigation() {
  return (
    <Navbar
      bg="light"
      expand="lg"
      sticky="top"
      collapseOnSelect
      className="overflow-hidden"
    >
      <Container>
        <Navbar.Brand as={Link} to={getUrlForHome()}>
          {process.env.PUBLIC_URL === "https://tomekzaw.pl/ttss" ? (
            <>
              Komunikacja Miejska{" "}
              <span className="d-none d-md-inline-block">w Krakowie</span>
            </>
          ) : (
            "Odjazdowy Kraków"
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to={getUrlForMap()} eventKey="0">
              Mapa
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForRoutes()} eventKey="0">
              Linie
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForStops()} eventKey="0">
              Przystanki
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForVehiclesOnRoutes()} eventKey="0">
              <span className="text-nowrap">Pojazdy na liniach</span>
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForVehicles()} eventKey="0">
              <span className="text-nowrap">Baza pojazdów</span>
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForBlocks("mpk")} eventKey="0">
              Brygady
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForHiddenTrips()} eventKey="0">
              <span className="text-nowrap">Zjazdy/wyjazdy</span>
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForStatus()} eventKey="0">
              Status
            </Nav.Link>
            <Nav.Link as={Link} to={getUrlForAbout()} eventKey="0">
              Informacje
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
