import { KeyboardEvent, useCallback } from "react";

import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import Form from "react-bootstrap/Form";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import { StopLink } from "../components/StopLink";
import { getUrlForStop } from "../urls";
import { useFavoriteStops } from "../hooks/useFavoriteStops";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StopGroup, useStopGroups } from "../hooks/useStopGroups";

function searchStopGroupsByName(stopGroups: StopGroup[], query: string) {
  const pattern = query.toLocaleLowerCase();
  return stopGroups.filter((stopGroup) =>
    stopGroup.stop_name.toLocaleLowerCase().includes(pattern)
  );
}

export function Stops() {
  const { stopGroups, loading, error } = useStopGroups();

  const favoriteStops = useFavoriteStops();

  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get("q") ?? "";

  const results = searchStopGroupsByName(stopGroups, query);

  const navigate = useNavigate();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        if (results.length === 1) {
          navigate(getUrlForStop(results[0].stop_name));
        }

        const result = results.find(
          (stop) =>
            stop.stop_name.toLocaleLowerCase() === query.toLocaleLowerCase()
        );
        if (result !== undefined) {
          navigate(getUrlForStop(result.stop_name));
        }
      }
    },
    [navigate, query, results]
  );

  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const query = e.target.value;
      setSearchParams(query !== "" ? { q: query } : {}, { replace: true });
    },
    [setSearchParams]
  );

  return (
    <Container className="pb-5">
      <PageTitle title="Przystanki" />
      <h1 className="my-4">Przystanki</h1>
      <div className="mb-3 col-lg-4">
        <Form.Control
          type="text"
          placeholder="Wpisz nazwę przystanku..."
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {query.length === 0 && (
        <ul>
          {favoriteStops.map((stopName) => (
            <li key={stopName}>
              <StopLink stopName={stopName} link bold />
            </li>
          ))}
        </ul>
      )}
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : results.length ? (
        <ul>
          {results.map((stop) => (
            <li key={stop.stop_name}>
              <StopLink stopName={stop.stop_name} link />
            </li>
          ))}
        </ul>
      ) : (
        <p>Brak wyników</p>
      )}
    </Container>
  );
}
