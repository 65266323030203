import { useMemo } from "react";
import { useStops } from "./useStops";
import { removeNzFromStopName } from "../utils";

export function useStopsByName(stopName: string) {
  const { stops, loading, error } = useStops();

  const filteredStops = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return stops.filter(
      (stop) => stop.stop_name === removeNzFromStopName(stopName)
    );
  }, [stops, loading, error, stopName]);

  return { stops: filteredStops, loading, error };
}
