import { useMemo } from "react";
import { useActiveVehicles } from "./useActiveVehicles";
import { useVehicles, Vehicle } from "./useVehicles";

export type { Service } from "./useBlocks";

export interface ActiveTrip {
  route_short_name: string | null;
  trip_headsign: string | null;
  service_id: string | null;
  block_id: string | null;
  trip_id: string | null;
  timestamp: number;
}

export interface VehicleWithActiveTrip extends Vehicle {
  activeTrip: ActiveTrip | null;
}

export function useVehiclesWithActiveTrips() {
  const {
    vehicles,
    loading: vehiclesLoading,
    error: vehiclesError,
  } = useVehicles();

  const {
    timestamp,
    vehicles: activeVehicles,
    loading: activeVehiclesLoading,
    error: activeVehiclesError,
  } = useActiveVehicles("gtfs", 10_000);

  const vehiclesWithActiveTrips = useMemo(() => {
    const activeTripsByKmkId = new Map<string, ActiveTrip>();

    activeVehicles.forEach((activeVehicle) => {
      if (activeVehicle.kmk_id !== null && activeVehicle.timestamp !== null) {
        const activeTrip = {
          route_short_name: activeVehicle.route_short_name,
          trip_headsign: activeVehicle.trip_headsign,
          service_id: activeVehicle.service_id,
          block_id: activeVehicle.block_id,
          trip_id: activeVehicle.trip_id,
          timestamp: activeVehicle.timestamp,
        };
        activeTripsByKmkId.set(activeVehicle.kmk_id, activeTrip);
      }
    });

    return vehicles.map((vehicle) => ({
      ...vehicle,
      activeTrip: activeTripsByKmkId.get(vehicle.kmk_id) ?? null,
    }));
  }, [vehicles, activeVehicles]);

  const loading = vehiclesLoading || activeVehiclesLoading;

  const error = vehiclesError ?? activeVehiclesError ?? undefined;

  return {
    timestamp,
    vehiclesWithActiveTrips,
    loading,
    error,
  };
}
