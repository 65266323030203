import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { Category } from "./useBlock"; // TODO: move to common types
import { useFetch } from "usehooks-ts";
import { useCacheBuster } from "./useCacheBuster";

export interface StopTime {
  stop_sequence: number;
  stop_name: string;
  stop_num: string | null;
  stop_lat: number | null;
  stop_lon: number | null;
  departure_time: string;
  time: number | null;
  delay: number | null;
  old: boolean;
}

export interface StopTimeWithTripNum extends StopTime {
  trip_id: string;
}

export interface ShapePoint {
  latitude: number;
  longitude: number;
}

export type Shape = ShapePoint[];

export interface Trip {
  trip_id: string;
  route_short_name: string;
  trip_headsign: string;
  shape: Shape | null;
}

function useBlockStopTimes(
  category: Category,
  serviceId: string,
  blockId: string,
  interval: number
) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{
    stop_times: StopTimeWithTripNum[];
    trips: Trip[];
  }>(
    `${BACKEND_BASE_URL}/api/blocks/${category}/${serviceId}/${blockId}/stop_times?t=${cacheBuster}`
  );

  const [stopTimes, setStopTimes] = useState<StopTimeWithTripNum[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStopTimes(data.stop_times);
      setTrips(data.trips);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when category or blockId changes
    setStopTimes([]);
    setTrips([]);
    setLoading(true);
  }, [category, blockId]);

  return { stopTimes, trips, loading, error };
}

export function useTrip(
  category: Category,
  serviceId: string,
  blockId: string,
  tripId: string,
  interval: number = 10_000
) {
  const { stopTimes, trips, loading, error } = useBlockStopTimes(
    category,
    serviceId,
    blockId,
    interval
  );

  const trip = trips.find((trip) => trip.trip_id === tripId);

  const currentTripIndex = trips.findIndex((trip) => trip.trip_id === tripId);
  const prevTripId =
    currentTripIndex > 0 ? trips[currentTripIndex - 1].trip_id : null;
  const nextTripId =
    currentTripIndex < trips.length - 1
      ? trips[currentTripIndex + 1].trip_id
      : null;

  const tripStopTimes = stopTimes.filter(
    (stopTime) => stopTime.trip_id === tripId
  );

  return {
    trip,
    stopTimes: tripStopTimes,
    prevTripId,
    nextTripId,
    loading,
    error,
  };
}
