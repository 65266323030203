import { useParams } from "react-router-dom";
import { decodeStopName, isValidKmkId, splitStopNameAndNumber } from "../utils";
import { StopInner } from "./Stop";
import { VehicleInner } from "./Vehicle";

export function Shortcut() {
  const { shortcut } = useParams();

  if (shortcut === undefined) {
    return null;
  }

  if (isValidKmkId(shortcut)) {
    return <VehicleInner kmkId={shortcut} />;
  }

  const decodedStopName = decodeStopName(shortcut);

  const [stopName, stopNumber] = splitStopNameAndNumber(decodedStopName);

  return (
    <StopInner
      stopName={stopName}
      stopNumber={stopNumber}
      mode="current_stop_times"
    />
  );
}
