import { Block, Service, Stop, useRoute } from "../hooks/useRoute";
import { Link, useParams } from "react-router-dom";
import { decodeStopName, isInteger, splitStopNameAndNumber } from "../utils";
import { useMemo, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import { ServiceSelect } from "../components/ServiceSelect";
import Spinner from "react-bootstrap/Spinner";
import { Timetable } from "../components/Timetable";
import { useDepartures } from "../hooks/useDepartures";
import {
  getUrlForRouteDepartures,
  getUrlForRouteDeparturesFromStop,
} from "../urls";

interface DeparturesInnerInnerInnerProps {
  routeShortName: string;
  directionId: number;
  encodedStopName: string;
  serviceId: string;
}

function DeparturesInnerInnerInner({
  routeShortName,
  directionId,
  encodedStopName,
  serviceId,
}: DeparturesInnerInnerInnerProps) {
  const decodedStopName = decodeStopName(encodedStopName);

  const [stopName, stopNumber] = splitStopNameAndNumber(decodedStopName);

  const { departures, loading, error } = useDepartures(
    routeShortName,
    directionId,
    stopName,
    stopNumber
  );

  const filteredDepartures = useMemo(() => {
    return departures.filter((departure) => departure.service_id === serviceId);
  }, [departures, serviceId]);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return (
      <Spinner animation="border" variant="primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (filteredDepartures.length === 0) {
    return (
      <Alert variant="secondary">Brak odjazdów w wybrany dzień tygodnia.</Alert>
    );
  }

  return (
    <Timetable
      routeShortName={routeShortName}
      stopName={stopName}
      stopNumber={stopNumber}
      departures={filteredDepartures}
      serviceId={serviceId}
    />
  );
}

interface StopsListProps {
  stops: Stop[];
  encodedStopName: string | null;
  routeShortName: string;
  directionId: number;
}

function StopsList({
  stops,
  encodedStopName,
  routeShortName,
  directionId,
}: StopsListProps) {
  const decodedStopName = encodedStopName && decodeStopName(encodedStopName);

  const [stopName, stopNumber] =
    decodedStopName !== null
      ? splitStopNameAndNumber(decodedStopName)
      : [null, null];

  return (
    <ul className="list-unstyled mt-3">
      {stops.map((stop) => (
        <li
          key={stop.stop_id}
          className={
            stop.stop_name === stopName && stop.stop_num === stopNumber
              ? "bold"
              : undefined
          }
        >
          <Link
            to={getUrlForRouteDeparturesFromStop(
              routeShortName,
              directionId,
              stop.stop_name,
              stop.stop_num
            )}
            replace
          >
            {stop.stop_name}{" "}
            {stop.stop_num !== null && <small>{stop.stop_num}</small>}
          </Link>
        </li>
      ))}
    </ul>
  );
}

interface DeparturesInnerInnerProps {
  routeShortName: string;
  directionId: number;
  encodedStopName: string | null;
  stops: Stop[];
  services: Service[];
  blocks: Block[];
}

function isOperating(services: Service[], serviceId: string) {
  return !!services.find((service) => service.service_id === serviceId)
    ?.operating;
}

function DeparturesInnerInner({
  routeShortName,
  directionId,
  encodedStopName,
  stops,
  services,
  blocks,
}: DeparturesInnerInnerProps) {
  const [serviceId, setServiceId] = useState<string | undefined>(
    () =>
      (
        blocks.find((block) => block.vehicles_on_block.length >= 1) ||
        blocks.find((block) => block.is_current)
      )?.service_id // any active vehicle or any current block
  );

  const filteredStops = useMemo(() => {
    return stops.filter((stop) => stop.direction_id === directionId);
  }, [stops, directionId]);

  const hasOppositeDirection = useMemo(() => {
    return stops.some((stop) => stop.direction_id !== directionId);
  }, [stops, directionId]);

  return (
    <div className="row">
      <div className="col-xl-4 mb-3">
        <h1 className="mt-0 mb-4">Linia {routeShortName}</h1>
        <ServiceSelect
          services={services}
          serviceId={serviceId}
          setServiceId={setServiceId}
          disabledFunc={(serviceId) => !isOperating(services, serviceId)}
        />
        <div className="mt-3">
          {hasOppositeDirection ? (
            <Link
              to={getUrlForRouteDepartures(routeShortName, 1 - directionId)}
              replace
              className="btn btn-outline-primary"
            >
              &#8635;&ensp;Przeciwny kierunek
            </Link>
          ) : (
            <span className="btn btn-outline-secondary disabled">
              &rarr;&ensp;Linia jednokierunkowa
            </span>
          )}
        </div>
        <StopsList
          stops={filteredStops}
          routeShortName={routeShortName}
          directionId={directionId}
          encodedStopName={encodedStopName}
        />
      </div>
      <div className="col-xl-6 mb-3">
        {serviceId === undefined ? (
          <Alert variant="secondary">Wybierz dzień tygodnia.</Alert>
        ) : encodedStopName === null ? (
          <Alert variant="secondary">Wybierz przystanek.</Alert>
        ) : (
          <DeparturesInnerInnerInner
            routeShortName={routeShortName}
            directionId={directionId}
            encodedStopName={encodedStopName}
            serviceId={serviceId}
          />
        )}
      </div>
    </div>
  );
}

interface DeparturesInnerProps {
  routeShortName: string;
  directionId: number;
  encodedStopName: string | null;
}

function DeparturesInner({
  routeShortName,
  directionId,
  encodedStopName,
}: DeparturesInnerProps) {
  const { stops, services, blocks, loading, error } = useRoute(routeShortName);

  return (
    <Container className="my-4">
      <PageTitle title={`Linia ${routeShortName}`} />
      {(error || loading) && (
        <h1 className="mt-0 mb-4">Linia {routeShortName}</h1>
      )}
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <DeparturesInnerInner
          routeShortName={routeShortName}
          directionId={directionId}
          encodedStopName={encodedStopName}
          stops={stops}
          services={services}
          blocks={blocks}
        />
      )}
    </Container>
  );
}

export function Departures() {
  const { routeShortName, directionId, encodedStopName } = useParams();

  if (routeShortName === undefined) {
    return null;
  }
  if (directionId === undefined || !isInteger(directionId)) {
    return null;
  }

  return (
    <DeparturesInner
      routeShortName={routeShortName}
      directionId={Number.parseInt(directionId)}
      encodedStopName={encodedStopName ?? null}
    />
  );
}
